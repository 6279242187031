/** @format */

import React, { useState, useEffect } from "react";

const textArray = [
  "Dang, no bullets left!",
"Slow down, Cowboy!",
"Bullets, no more!",
"Close, shoots no hare!",
"Click, click, click - Clip empty!",
"Missed the Mark, No More Shots!",
"Bullet supply chain, experiencing technical difficulties!",
"Error 404 - Bullets not found!",
"Click, click, click - the silence of a null pointer!",
"ammo_available = False",
"boolean outOfAmmo = true;",
"var ammoAvailable = false",
"let no_ammo = true; // Slow it down!!",

];

export const NoBulletText = () => {
  const [randomText, setRandomText] = useState("");

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * textArray.length);
    setRandomText(textArray[randomIndex]);
  }, []);

  return <div id="no-bullets-left">{randomText}</div>;
};
