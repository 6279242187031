/** @format */

import { createContext, useState } from "react";
import "./style/main.scss";
import { views } from "./constants";
import { If } from "./helpers/components";
import { CutsceneStart, Game, GameOver, Home, HowToPlay, CutsceneGameOver } from "./views";
import { Scoreboard } from "./components/Scoreboard";
import { Settings } from "./components/Settings";
import { settingsDetails, settings2 } from "./settings";

function transformSettings(details) {
  const transformedSettings = {};

  details.forEach((setting) => {
    for (const key in setting) {
      for (const subKey in setting[key]) {
        if (setting[key][subKey].category === key) {
          transformedSettings[subKey] =
            setting[key][subKey].newValue || setting[key][subKey].default;
        }
      }
    }
  });

  return transformedSettings;
}

export const SettingsContext = createContext(null);

export const App = () => {
  const [view, setView] = useState(views.HOME);
  const [theSettings, setTheSettings] = useState(
    (localStorage.getItem("settings") &&
      JSON.parse(localStorage.getItem("settings"))) ||
      settingsDetails
  );

  const testV = (localStorage.getItem("settings") && {
    ...transformSettings(JSON.parse(localStorage.getItem("settings"))),
    ...settings2,
  }) || {
    ...transformSettings(theSettings),
    ...settings2,
  };

  return (
    <div className="App">
      <SettingsContext.Provider value={testV}>
        <If condition={view === views.HOME}>
          <Home setView={setView} />
        </If>

        <If condition={view === views.HOW_TO_PLAY}>
          <HowToPlay setView={setView} />
        </If>

        <If condition={view === views.GAME}>
          <Game setView={setView} />
        </If>

        <If condition={view === views.GAME_OVER}>
          <GameOver setView={setView} />
        </If>

        <If condition={view === views.CUTSCENE_START}>
          <CutsceneStart setView={setView} />
        </If>

        <If condition={view === views.SETTINGS}>
          <Settings
            theSettings={theSettings}
            setTheSettings={setTheSettings}
            setView={setView}
          />
        </If>

        <If condition={view === views.CUTSCENE_GAME_OVER}>
          <CutsceneGameOver setView={setView} />
        </If>

        <If condition={view !== views.SETTINGS}>
          <Scoreboard />
        </If>

        

      </SettingsContext.Provider>
    </div>
  );
};
