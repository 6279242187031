/** @format */

import { views } from "../../constants";
import { useGetAllScores } from "../../helpers/hooks";
import { settings } from "../../settings";

export const Home = ({ setView }) => {
  const { data, isLoading } = useGetAllScores();

  const handleStartGame = () => {
    setView(views.CUTSCENE_START);
    setTimeout(() => {
      setView(views.GAME);
    }, settings.startGameDelay * 1000);
  };

  if (isLoading || !data) return <>loading</>;

  return (
    <>
      <div id="home">
        <div className="logo">
          <h2 className="title">Detechtiv</h2>
          <div className="divider"></div>
          <h2 className="title">Hunt</h2>
        </div>
        <div className="top-score">
          <h3>Top score: {data.data[0].score}</h3>
        </div>
        <div className="home-buttons">
          <button onClick={() => handleStartGame()}>start game</button>
          {/* <button onClick={() => setView(views.GAME)}>start game</button> */}
          <button onClick={() => setView(views.HOW_TO_PLAY)}>
            how to play
          </button>
          {/* <button>Score board</button> */}
        </div>
        <div className="credits">
          A game developed by: Olof Aksberg and Mattias Lager
        </div>
        {/* <div onClick={() => setView(views.SETTINGS)}>settings</div> */}
      </div>
    </>
  );
};
