/** @format */

export const api_address = "http://localhost:3030";

// get
export const GET = async (endpoint) => {
  return await fetch(api_address + endpoint, {
    method: "GET",
    headers: {
      accept: "application/json",
    },
  }).then((res) => res.json());
};

export const PROTECTED_GET = async (endpoint, authLevel) => {
  return await fetch(api_address + endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //   authorization: getAuthLevel(authLevel),
    },
  }).then((res) => res.json());
};

// post
export const POST = async (endpoint, data, auth) => {
  return await fetch(api_address + endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      auth: auth,
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const PROTECTED_POST = async (endpoint, data, authLevel) => {
  return await fetch(api_address + endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      //   authorization: getAuthLevel(authLevel),
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
};
