/** @format */

import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { settings as settings2 } from "../../../settings";
import { drawEnemy, drawCrosshair, updateEnemy, handleOnShot } from "./utils";
import { If } from "../../../helpers/components";
import { BetweenRoundsCutScene } from "../components";
import { useSounds } from "../../../helpers/hooks";
import { onShotOutcomes } from "../../../constants";
import { SettingsContext } from "../../../App";

const foregroundImage = require(`../../../assets/images/${settings2.foregroundImage}`);
const kurreImage = require(`../../../assets/images/${settings2.kurreImage}`);
const doggyImage = require(`../../../assets/images/${settings2.doggyImage}`);
const backgroundImage = require(`../../../assets/images/${settings2.backgroundImage}`);

const subRoundDogHoldingEnemiesImage = require(`../../../assets/images/${settings2.subRoundDogHoldingEnemiesImage}`);

const initSubRoundDogObj = {
  frameX: 0,
  frameY: 0,
  width: 135,
  height: 186,
  x: 0,
  y: settings2.cavnasHeight - 200,
  goingDown: false,
  dontDraw: false,
};

export const messageDuration = 1;
const messageFontColor = "white";

export const Canvas = ({
  cutScene,
  bullets,
  setScore,
  currentEnemies,
  setCurrentEnemies,
}) => {
  const settings = useContext(SettingsContext);
  const foregroundImageHeight = 279;
  const image = new Image();
  image.src = foregroundImage;

  const backImage = new Image();
  const backWidth = 768;
  backImage.src = backgroundImage;

  const sqImage = new Image();
  const sqWidth = 108;
  sqImage.src = kurreImage;

  const pupImage = new Image();
  const pupWidth = 96;
  const pupHeight = 102;
  pupImage.src = doggyImage;

  const dogImage = new Image();
  dogImage.src = subRoundDogHoldingEnemiesImage;
  const canvasRef = useRef();
  const canvasRef2 = useRef();
  const [frame, setFrame] = useState(0);
  const [windyTime, setWindyTime] = useState({ wind: false, frameX: 0 });
  const [kurreTime, setKurreTime] = useState({ kurre: false, frameX: 0 });
  const [dogTime, setDogTime] = useState({ puppy: false, frameX: 0 });
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  const [lastDeadEnemyPositionX, setLastDeadEnemyPositionX] = useState(false);
  const [subRoundDog, setSubRoundDog] = useState(() => ({
    ...initSubRoundDogObj,
    image: dogImage,
  }));

  const [messages, setMessages] = useState([]);

  const { playGunShotSound, playDoubleKillSound } = useSounds();

  const handleMouseMove = useCallback((e, ref) => {
    const canvas = ref.current;
    const newMousePosition = {
      x: e.clientX - canvas.getBoundingClientRect().left + window.scrollX,
      y: e.clientY - canvas.getBoundingClientRect().top + window.scrollY,
    };

    setMousePosition(newMousePosition);
  }, []);

  useLayoutEffect(() => {
    let timerId;
    const animate = () => {
      setFrame((prev) => prev + 1);
      timerId = requestAnimationFrame(animate);
    };
    timerId = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(timerId);
  }, []);

  useEffect(() => {
    const canvas =
      canvasRef && canvasRef.current
        ? canvasRef.current
        : canvasRef2 && canvasRef2.current
        ? canvasRef2.current
        : null;

    if (!canvas) return;
    // if (canvas) {
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, settings.canvasWidth, settings.cavnasHeight);

    if (kurreTime.kurre) {
      ctx.drawImage(
        sqImage,
        sqWidth * kurreTime.frameX,
        0,
        sqWidth,
        72,
        100,
        220,
        sqWidth,
        72
      );
      if (!windyTime.wind) {
        if (frame % 5 === 0) {
          // if (frame % settings.kurreMovingSpeed === 0) {
          if (kurreTime.frameX === 27) {
            setKurreTime({ kurre: false, frameX: 0 });
          } else {
            setKurreTime((prev) => ({ ...prev, frameX: prev.frameX + 1 }));
          }
        }
      }
    } else {
      if (frame % 1000 === 0) {
        // if (frame % settings.kurreMovingIntervalTime === 0) {
        setKurreTime({ kurre: true, frameX: 0 });
      }
    }
    // console.log(kurreTime);

    

    if (windyTime.wind) {
      ctx.drawImage(
        backImage,
        backWidth * windyTime.frameX,
        0,
        backWidth,
        720,
        0,
        0,
        settings.canvasWidth,
        settings.cavnasHeight
      );

      if (frame % settings.backgroundMovingSpeed === 0) {
        if (windyTime.frameX === 3) {
          setWindyTime({ wind: false, frameX: 0 });
        } else {
          setWindyTime((prev) => ({ ...prev, frameX: prev.frameX + 1 }));
        }
      }
    } else {
      ctx.drawImage(
        backImage,
        0,
        0,
        backWidth,
        720,
        0,
        0,
        settings.canvasWidth,
        settings.cavnasHeight
      );
      if (frame % settings.backgroundMovingIntervalTime === 0) {
        setWindyTime({ wind: true, frameX: 0 });
      }
    }
    // console.log(frame);

    if (dogTime.puppy) {
      ctx.drawImage(
        pupImage,
        pupWidth * dogTime.frameX,
        0,
        pupWidth,
        pupHeight,
        540,
        370,
        pupWidth,
        pupHeight
      );
      if (frame % 5 === 0) {
        // if (frame % settings.kurreMovingSpeed === 0) {
        if (dogTime.frameX === 46) {
          setDogTime({ puppy: false, frameX: 0 });
        } else {
          setDogTime((prev) => ({ ...prev, frameX: prev.frameX + 1 }));
        }
      }
    } else {
      if (frame % 4000 === 1000) {
        // if (frame % settings.kurreMovingIntervalTime === 0) {
        setDogTime({ puppy: true, frameX: 0 });
      }
    }

    if (canvas === canvasRef.current) {
      const newBirdsState = [];
      for (let i = 0; i < currentEnemies.length; i++) {
        const newBirdState = updateEnemy(
          currentEnemies[i],
          canvas,
          frame,
          setLastDeadEnemyPositionX,
          bullets,
          settings
        );

        drawEnemy(newBirdState, canvas);
        newBirdsState.push(newBirdState);
        
      }

      setCurrentEnemies(newBirdsState);
      drawCrosshair(canvas, mousePosition);
    }

    if (canvas === canvasRef2.current) {
      if (cutScene.type === "subRound") {
        ctx.drawImage(
          subRoundDog.image,
          subRoundDog.frameX,
          subRoundDog.frameY,
          subRoundDog.width,
          subRoundDog.height,
          lastDeadEnemyPositionX,
          subRoundDog.y,
          subRoundDog.width,
          subRoundDog.height
        );
        let newSubRoundDogState = subRoundDog;

        if (!subRoundDog.dontDraw) {
          if (!subRoundDog.goingDown) {
            if (subRoundDog.y < settings.cavnasHeight - 410) {
              newSubRoundDogState = { ...newSubRoundDogState, goingDown: true };
            } else {
              newSubRoundDogState = {
                ...newSubRoundDogState,
                y: newSubRoundDogState.y - 2,
              };
            }
          } else {
            if (subRoundDog.y > settings.cavnasHeight - 200) {
              newSubRoundDogState = { ...newSubRoundDogState, dontDraw: true };
            } else {
              newSubRoundDogState = {
                ...newSubRoundDogState,
                y: newSubRoundDogState.y + 2,
              };
            }
          }

          setSubRoundDog(newSubRoundDogState);
        }
      }
    }
    if (messages.length) {
      const newMessagesState = [];
      for (let i = 0; i < messages.length; i++) {
        const msg = messages[i];
        ctx.font = "20px Arial";
        ctx.fillStyle = messageFontColor;
        ctx.fillText(msg.text, msg.positionX, msg.positionY);

        if (msg.countDown !== 0) {
          newMessagesState.push({
            ...msg,
            countDown:
              frame % messageDuration === 0 ? msg.countDown - 1 : msg.countDown,
          });
        }
      }

      setMessages(newMessagesState);
    }

    if (cutScene.type === "subRound") {
      ctx.drawImage(
        subRoundDog.image,
        subRoundDog.frameX,
        subRoundDog.frameY,
        subRoundDog.width,
        subRoundDog.height,
        lastDeadEnemyPositionX,
        subRoundDog.y,
        subRoundDog.width,
        subRoundDog.height
      );
      let newSubRoundDogState = subRoundDog;

      if (!subRoundDog.dontDraw) {
        if (!subRoundDog.goingDown) {
          if (subRoundDog.y < settings.cavnasHeight - 410) {
            newSubRoundDogState = { ...newSubRoundDogState, goingDown: true };
          } else {
            newSubRoundDogState = {
              ...newSubRoundDogState,
              y: newSubRoundDogState.y - 2,
            };
          }
        } else {
          if (subRoundDog.y > settings.cavnasHeight - 200) {
            newSubRoundDogState = { ...newSubRoundDogState, dontDraw: true };
          } else {
            newSubRoundDogState = {
              ...newSubRoundDogState,
              y: newSubRoundDogState.y + 2,
            };
          }
        }

        setSubRoundDog(newSubRoundDogState);
      }
    }

    drawCrosshair(canvas, mousePosition);

    ctx.drawImage(
      image,
      0,
      0,
      settings.canvasWidth,
      settings.cavnasHeight,
      0,
      settings.cavnasHeight - foregroundImageHeight,
      settings.canvasWidth,
      settings.cavnasHeight
    );
  }, [frame]);

  useEffect(() => {
    if (subRoundDog.dontDraw) {
      setSubRoundDog({
        ...initSubRoundDogObj,
        image: dogImage,
      });
    }
  }, [cutScene]);

  if (cutScene) {
    return (
      <>
        {cutScene.type === "round" && (
          <BetweenRoundsCutScene cutScene={cutScene} />
        )}
        <canvas
          className="canvas1"
          ref={canvasRef2}
          width={settings.canvasWidth}
          height={settings.cavnasHeight}
          tabIndex="0"
          onMouseMove={(e) => handleMouseMove(e, canvasRef2)}
        />
      </>
    );
  }

  return (
    <canvas
      className="canvas1"
      ref={canvasRef}
      width={settings.canvasWidth}
      height={settings.cavnasHeight}
      tabIndex="0"
      onMouseMove={(e) => handleMouseMove(e, canvasRef)}
      onClick={(e) => {
        if (bullets === 0) {
          return;
        }
        playGunShotSound();
        const result = handleOnShot(
          e,
          settings,
          currentEnemies,
          setCurrentEnemies,
          setScore,
          canvasRef,
          setMessages
        );
        if (result === onShotOutcomes.DOUBLE_KILL) {
          playDoubleKillSound();
        }
      }}
    />
  );
};
